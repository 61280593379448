<template>
  <div id="divMenuSettingsWrapper" class="menu-wrapper">
    <VueContentHolder class="menu">
      <VueContentHolder :padding="[20, 25]">
        <VueListItem
          as="router-link"
          :to="item.to"
          :text="item.text"
          :contentAlignment="constants.flexAlignment.between"
          v-for="item in items"
          :key="item.id"
          :badge="item.badge"
          :count="item.badge"
          :radius="2500"
          badgeColor="red-20"
        ></VueListItem>
      </VueContentHolder>
    </VueContentHolder>
  </div>
</template>
<script>
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import { mapMutations } from 'vuex';

import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'SettingsNavigation',
  data() {
    return {
      items: [
        {
          text: 'Profil',
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.Profile.path}`,
        },
        {
          text: 'PMI',
          badge: 6,
          to: `/secure/business-partners/pmi`,
        },
        {
          text: 'Şifre Değiştir',
          badge: 6,
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.ChangePassword.path}`,
        },
        {
          text: 'Bildirimler',
          badge: 6,
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.Notifications.path}`,
        },
        {
          text: 'Bize Ulaşın',
          badge: 6,
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.ContactUs.path}`,
        },
        {
          text: 'Belgelerim',
          badge: 6,
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.MyDocuments.path}`,
        },
        {
          text: 'Gizlilik Politikası',
          badge: 6,
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.PrivacyPolicy.path}`,
        },
        {
          text: 'Uygulama Hakkında',
          badge: 6,
          to: `${RoutesSecure.Settings.path}/${RoutesSettings.AboutApp.path}`,
        },
      ],
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  components: { VueListItem, VueContentHolder },
  methods: {
    ...mapMutations({
      setUserModel: 'auth/SET_USER_MODEL',
      setAccessToken: 'auth/SET_ACCESS_TOKEN',
    }),
  },
};
</script>

<style scoped lang="scss">
.menu {
  background-color: palette-color-level('white', '100');
}

.quit-button {
  display: flex;
  align-items: center;
  padding: palette-space-level('20') palette-space-level('20');
}
</style>
